import React from 'react';
import Styled from 'styled-components'
import Layout from '../components/layout'
import {Paragraph, Title, SubTitle, Container, Fancy } from '../components/page-styles';

const Email = Styled.a`
  font-size: ${21/16}rem;
  font-weight: 300;
  strong {
    font-weight: 500;
  }
`
const Small = Styled.small`
  ${Paragraph}, ${Fancy} {
    font-size: ${14/16}rem;
    margin-bottom: ${16/16}rem;
`

const TalentSubmissionsPage = () => (
  <Layout>
    <Container style={{maxWidth: 657}}>
      <Title>Submission Instructions</Title>
      <SubTitle>Actors</SubTitle>
      <Paragraph>If you are looking for representation, please forward your materials including photos, resume, link to demo reel/scene work (if available), with the subject ‘TALENT SUBMISSION’ to:</Paragraph>
      <Email href="mailto:office@ncatalent.com"><strong>Rick Gerrits</strong> - office@ncatalent.com</Email>
      <br />
      <br />

      <SubTitle>Voice & Animation</SubTitle>
      <Paragraph>Please email materials (resume, link to demo) to:</Paragraph>
      <Email href="mailto:james.crammond@ncatalent.com" style={{marginBottom: 120}}><strong>James Crammond</strong> - james.crammond@ncatalent.com</Email>

      <Small>
        <Paragraph>Due to the high volume of submissions we receive, we will be in contact only if an agent is interested in setting up a meeting. Thank you.</Paragraph>
        <Fancy style={{marginBottom: 25}}>** Electronic submissions preferred. NOTE: we prefer not to receive packages shared via Google Drive (browser links OK) – your cooperation is appreciated.</Fancy><br />
        <Fancy>Please note that Noble Caplan Abrams does not represent background talent.</Fancy>
      </Small>
    </Container>
  </Layout>
);

export default TalentSubmissionsPage;
